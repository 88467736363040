<template>
  <b-card class="file-preview overflow-hidden" body-class="p-0">
    <button-close
      v-if="cancelable"
      class="file-preview__cancel zindex-4"
      :disabled="disabled"
      @click="$emit('cancel', index)"
    />
    <safe-img
      v-if="type === 'image'"
      :src="src"
      :placeholder="placeholderImage"
      :class="hasMaxHeight?'max-height':''"
      class="d-block mw-100 file-preview__content"
    />
    <video-player
      v-else-if="type === 'video'"
      :src="src"
      :type="mimeType"
      class="mw-100 file-preview__content"
    />
    <b-link
      v-else
      :href="src"
      target="_blank"
      class="file-preview__content d-flex flex-column align-items-center"
    >
      <feather-icon v-if="type==='audio'" icon="MusicIcon" size="128" />
      <feather-icon v-else icon="FileTextIcon" size="128" />
      <h5 v-if="fileName" class="mt-1 px-25">
        {{ fileName }}
      </h5>
    </b-link>
  </b-card>
</template>

<script>
import { BCard, BLink } from 'bootstrap-vue';
import ButtonClose from '@core/components/buttons/ButtonClose.vue';
import VideoPlayer from '@core/components/video-player/VideoPlayer.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  name: 'FilePreview',
  components: {
    BCard, BLink, ButtonClose, VideoPlayer, SafeImg,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      required: false,
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    placeholderImage: {
      type: String,
      default: '',
    },
    hasMaxHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      if (this.mimeType === 'image' || this.mimeType.startsWith('image/')) {
        return 'image';
      }
      if (this.mimeType === 'video' || this.mimeType.startsWith('video/')) {
        return 'video';
      }
      if (this.mimeType === 'audio' || this.mimeType.startsWith('audio/')) {
        return 'audio';
      }
      return 'document';
    },
  },
};
</script>

<style lang="scss" scoped>
  .file-preview {
    position: relative;
    display: inline-block;
    &__cancel {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &__content {
      min-width: 100px;
      min-height: 50px;
    }
  }
  .max-height {
    max-height: 180px;
    height: 180px;
    width: auto;
  }
</style>
